<template>
   <segments-template />
</template>
<script>

// Components
import SegmentsTemplate from '@/components/segments/SegmentsTemplate.vue'

export default {
  name: 'SitesView',
  components: {
    SegmentsTemplate
  }
}

</script>
