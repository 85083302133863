<template>
<div>
  <segment-depth-form :form-is-open="depthFormIsOpen" :segment-data="segmentData" @close-depth-form="closeDepthForm" @submit-depth-form="submitDepthForm" />
  <v-container
     style="width: 100%; max-width: 100%;">
   <v-data-table
     :headers="headers"
     :items="segmentsData"
      item-key="id"
     :expanded="expandedRows"
     class="inverse-header-color"
   >
      <template v-slot:item="{ item, expand, isExpanded }">
        <tr :class="`${isExpanded ? 'expanded-row' : ''}`">
          <td class="d-block d-sm-table-cell" v-for="header in headers" :key="`row-${item.id}-field-${header.value}}`">
            <template v-if="header.value == 'type'">
              <div class="d-flex justify-center">
                <v-chip small :color="chipColor(item.type.name)">{{ item.type.name }}</v-chip>
              </div>
            </template>
            <template v-else-if="header.value == 'status'">
              <div class="d-flex justify-center">
                <v-chip small :color="chipColor(item.status.name)">{{ item.status.name }}</v-chip>
              </div>
            </template>
            <template v-else-if="header.value == 'geoLocation'">
              {{ item.latitude && item.longitude ? `${item.latitude}, ${item.longitude}` : '-' }}
            </template>
            <template v-else-if="header.value == 'haulsSent'">
              {{ item.sent ? `${item.sent.actual}/${item.sent.scheduled}` : '0/0' }}
            </template>
            <template v-else-if="header.value == 'haulsReceived'">
              {{ item.sent ? `${item.received.actual}/${item.received.scheduled}` : '0/0' }}
            </template>
            <template v-else-if="header.value == 'actions'">
              <div class="d-flex justify-end">
                <v-btn elevation="0" x-small class="mr-1" color="success" @click="openDepthForm(item)">+Depth</v-btn>
                <v-btn elevation="0" x-small class="mr-1" color="info" @click="editSegment(item)">Edit</v-btn>
                <v-btn elevation="0" x-small class="mr-1" color="error" outlined @click="deleteSegment(item)">Delete</v-btn>
                <v-btn elevation="0" x-small class="mr-1" color="info" outlined @click="viewMap(item)">Map</v-btn>
              </div>
            </template>
            <template v-else-if="header.value == 'expand'">
              <a @click="expandRow(item, expand, isExpanded)">
                <v-icon color="primary" v-if="!item.expanded">mdi-chevron-right</v-icon>
                <v-icon color="primary" v-else>mdi-chevron-down</v-icon>
              </a>
            </template>
            <template v-else>
              {{item[header.value]}}
            </template>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <tr class="expanded-row-content">
          <td :colspan="headers.length">
            <div class="ma-4 ml-10 mr-10" style="border: 1px #7E7E7E solid">
              <segments-depth-table :loadSegmentDepths="loadSegmentDepths" :segmentId="item.id" />
            </div>
          </td>
        </tr>
      </template>
   </v-data-table>
 </v-container>
 </div>
</template>

<script>
import SegmentsDepthTable from './SegmentsDepthTable.vue'
import SegmentDepthForm from './SegmentDepthForm.vue'
import { chipColorClass } from '@/helpers/StylingHelper.js'

export default {
  components: { SegmentsDepthTable, SegmentDepthForm },
  props: {
    segmentsData: {
      type: Array,
      default: () => {}
    }
  },
  data: () => ({
    expandedRows: [],
    depthFormIsOpen: false,
    segmentData: null,
    loadSegmentDepths: false
  }),
  computed: {
    headers () {
      const headers = []
      headers.push({ text: '', value: 'expand', width: '100px', sortable: false })
      headers.push({ text: 'Type', value: 'type', sortable: false, align: 'center' })
      headers.push({ text: 'Code', value: 'code', sortable: false })
      headers.push({ text: 'Name', value: 'name', sortable: false })
      headers.push({ text: 'Geo Location', value: 'geoLocation', sortable: false })
      if (this.segmentsData.some((segment) => segment.type.id === 1)) headers.push({ text: 'Hauls Sent', value: 'haulsSent', sortable: false })
      if (this.segmentsData.some((segment) => segment.type.id === 2)) headers.push({ text: 'Hauls Received', value: 'haulsReceived', sortable: false })
      headers.push({ text: 'Status', value: 'status', sortable: false, align: 'center' })
      headers.push({ text: 'Actions', value: 'actions', align: 'right', sortable: false })
      return headers
    }
  },
  methods: {
    viewMap (item) {
      console.log('to-do: view map. segment ' + item.id + ' clicked:')
      console.log(item)
    },
    editSegment (item) {
      console.log('to-do: edit segment form. segment ' + item.id + ' clicked:')
      console.log(item)
    },
    deleteSegment (item) {
      console.log('to-do: delete segment. segment ' + item.id + ' clicked:')
      console.log(item)
    },
    submitDepthForm () {
      this.loadSegmentDepths = true
    },
    chipColor (name) {
      return chipColorClass(name)
    },
    expandRow (item, expandCallback, isExpanded) {
      expandCallback(!isExpanded)
      item.expanded = !isExpanded
    },
    openDepthForm (item) {
      this.loadSegmentDepths = false
      this.segmentData = item
      this.depthFormIsOpen = true
    },
    closeDepthForm () {
      // this.loadSegmentDepths = false - this will not reload the segmentDepthsTable after submitting if this is here
      this.depthFormIsOpen = false
    }
  }
}
</script>
<style>
  tr.expanded-row td {
    border-bottom: none !important;
  }
  .expanded-row-content:hover {
    background-color: transparent !important;
  }
  .inverse-header-color.theme--light div.v-data-table__wrapper table thead.v-data-table-header {
    background-color: #555555;
  }
  .inverse-header-color.theme--light div.v-data-table__wrapper table thead.v-data-table-header tr th span {
    color: white;

  }
  .inverse-header-color.theme--dark div.v-data-table__wrapper table thead.v-data-table-header {
    background-color: #aaaaaa;
  }
  .inverse-header-color.theme--dark div.v-data-table__wrapper table thead.v-data-table-header tr th span {
    color: black;

  }
</style>
