<template>
  <div>
    <segment-depth-range-form
      :segment-depth-range-form-is-open="segmentDepthRangeFormIsOpen"
      @close-segment-depth-range-form="closeSegmentDepthRangeForm"
      @submit-segment-depth-range-form="createSegmentDepthRange"
    />
    <v-row justify="center">
      <v-col cols="12">
        <v-dialog
          v-model="formOpen"
          persistent
          style="width: 80%"
          width="60%"
          min-width="600px"
        >
          <v-card style="width: 100%">
            <v-card-title>New Depth</v-card-title>
            <v-card-text>
              <v-container style="width: 100%">
                <v-form v-model="valid" ref="form">
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        label="Name"
                        v-model="submitData.name"
                        :rules="[rules.required]"
                      >
                        <template #label> Name<red-asterisk /> </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        v-model="submitData.depthId"
                        :items="depths"
                        item-text="name"
                        item-value="id"
                        :rules="[rules.required]"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item
                            @click="openSegmentDepthRangeForm"
                            style="cursor: pointer"
                          >
                            <v-icon>mdi-plus</v-icon>
                            Add Depth Range
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                        <template #label>
                          Depth Range<red-asterisk />
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row class="text-right">
                    <v-spacer></v-spacer>
                    <v-btn text class="mr-2" @click="closeForm">Close</v-btn>
                    <v-btn variant="outlined" @click="submitDepth" elevation="0"
                      >Submit</v-btn
                    >
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SegmentDepthRangeForm from '@/components/segments/SegmentDepthRangeForm.vue'

export default {
  components: {
    SegmentDepthRangeForm
  },
  props: {
    formIsOpen: {
      type: Boolean,
      default: false
    },
    segmentData: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    formOpen: false,
    segmentDepthRangeFormIsOpen: false,
    valid: false,
    depths: [],
    defaultUnitId: 3,
    defaultSubmitData: {
      name: '',
      depthId: null
    },
    submitData: {},
    rules: {
      required: (value) => !!value || 'Required.'
    }
  }),
  watch: {
    formIsOpen: {
      handler (value) {
        this.formOpen = value
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      if (this.depths.length <= 0) {
        await this.getDepths()
      }
    },
    async submitDepth () {
      this.submitData.segmentId = this.segmentData.id // not sure where to put this
      this.submitData.typeId = this.segmentData.siteSegmentTypeId
      this.submitData.unitId = this.defaultUnitId
      if (!this.$refs.form.validate()) return
      const res = await this.$api.segmentDepths.post(this.submitData)
      if (res.id) {
        this.$store.dispatch('app/showSuccess', 'Segment successfully Segment Depth')
        this.$emit('submit-depth-form')
        this.closeForm()
      } else {
        this.$store.dispatch('app/showError', 'Failed to create Segment Depth')
      }
    },
    closeForm () {
      this.resetForm()
      this.$emit('close-depth-form')
    },
    resetForm () {
      this.$refs.form.resetValidation()
      this.submitData = { ...this.defaultSubmitData }
    },
    async getDepths () {
      const res = await this.$api.depths.get()
      this.depths = res
    },
    createSegmentDepthRange (newDepthRange) {
      // this function will cause the dropdown to add the new model without refreshing
      this.depths.push(newDepthRange)
      this.submitData.depthId = newDepthRange.id
    },
    openSegmentDepthRangeForm () {
      this.segmentDepthRangeFormIsOpen = true
    },
    closeSegmentDepthRangeForm () {
      this.segmentDepthRangeFormIsOpen = false
    }
  }
}
</script>

<style>
.v-dialog > .v-overlay__content {
  width: 80%;
}
</style>
