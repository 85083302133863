<template>
  <v-row justify="center">
    <v-col cols="12">
      <v-dialog
        v-model="formOpen"
        persistent
        style="width: 80%"
        width="80%"
        min-width="600px"
      >
        <v-card style="width: 100%">
          <v-card-title>New Segment</v-card-title>
          <v-card-text>
            <v-container style="width: 100%">
              <v-form v-model="valid" ref="form">
                <v-row>
                  <v-col cols="2">
                    <v-autocomplete
                      no-data-text="Segment Types Not Found"
                      v-model="submitData.siteSegmentTypeId"
                      :items="siteSegmentTypes"
                      item-text="name"
                      item-value="id"
                      :rules="[rules.required]"
                    >
                        <template #label> Segment Type<red-asterisk /> </template>
                      </v-autocomplete>
                  </v-col>
                  <v-col cols="10">
                    <v-text-field
                      v-model="submitData.name"
                      :rules="[rules.required]"
                    >
                      <template #label> Name<red-asterisk /> </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row class="text-right">
                  <v-spacer></v-spacer>
                  <v-btn text class="mr-2" @click="closeForm">Close</v-btn>
                  <v-btn variant="outlined" @click="submitSegment" elevation="0"
                    >Submit</v-btn
                  >
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    formIsOpen: {
      type: Boolean,
      default: false
    },
    site: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    formOpen: false,
    valid: false,
    siteSegmentTypes: [],
    defaultSubmitData: {
      name: '',
      siteSegmentTypeId: null,
      siteId: null
    },
    submitData: {},
    rules: {
      required: (value) => !!value || 'Required.'
    }
  }),
  watch: {
    formIsOpen: {
      handler (value) {
        this.formOpen = value
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      if (this.siteSegmentTypes.length <= 0) {
        await this.getSiteSegmentTypes()
      }
    },
    async submitSegment () {
      this.submitData.siteId = this.site.id // not sure where to put this
      if (!this.$refs.form.validate()) return
      const res = await this.$api.segments.post(this.submitData)
      if (res.id) {
        this.$store.dispatch('app/showSuccess', 'Segment successfully created')
        this.$emit('submit-form')
        this.closeForm()
      } else {
        this.$store.dispatch('app/showError', 'Failed to create Segment')
      }
    },
    closeForm () {
      this.resetForm()
      this.$emit('close-form')
    },
    resetForm () {
      this.$refs.form.resetValidation()
      this.submitData = { ...this.defaultSubmitData }
    },
    async getSiteSegmentTypes () {
      const res = await this.$api.segmentTypes.get()
      this.siteSegmentTypes = res
    }
  }
}
</script>

<style>
.v-dialog > .v-overlay__content {
  width: 80%;
}
</style>
