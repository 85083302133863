<template>
  <v-container style="width: 100%; max-width: 100%" class="ml-0 mr-0">
   <v-data-table
     :headers="headers"
     :items="segmentDepths"
      item-key="id"
   >
      <template v-slot:item="{ item }">
        <tr>
          <td class="d-block d-sm-table-cell" v-for="(header, headerIndex) in headers" :key="`segment-${item.segmentId}-depth-${item.id}-field-${headerIndex}`">
            <template v-if="header.value == 'depth'">
              {{item.depth ? item.depth.name : '-'}}
            </template>
            <template v-else-if="header.value == 'depthUnitOfMeasurement'">
              {{item.depth && item.depth.unit ? item.depth.unit.name : '-'}}
            </template>
            <template v-else-if="header.value == 'volumeUnitOfMeasurement'">
              {{ item.unitOfVolume ? item.unitOfVolume.shortName : '-'}}
            </template>
            <template v-else-if="header.value == 'exportVolume'">
              {{ item.exportVolume != null ? item.exportVolume.toLocaleString() : '-' }}
            </template>
            <template v-else-if="header.value == 'importVolume'">
              {{ item.importVolume != null ? item.importVolume.toLocaleString() : '-' }}
            </template>
            <template v-else-if="header.value == 'status'">
              <div class="d-flex justify-center">
                <v-chip small :color="chipColor(item.status.name)">{{ item.status.name }}</v-chip>
              </div>
            </template>
            <template v-else-if="header.value == 'haulsSent'">
              {{ item.sent ? `${item.sent.actual}/${item.sent.scheduled}` : '0/0' }}
            </template>
            <template v-else-if="header.value == 'haulsReceived'">
              {{ item.sent ? `${item.received.actual}/${item.received.scheduled}` : '0/0' }}
            </template>
            <template v-else-if="header.value == 'actions'">
              <div class="d-flex justify-end">
                <v-btn elevation="0" x-small class="mr-1" color="info" @click="editDepth(item)">Edit</v-btn>
                <v-btn elevation="0" x-small class="mr-1" color="error" outlined @click="deleteDepth(item)">Delete</v-btn>
              </div>
            </template>
            <template v-else>
              {{item[header.value] ? item[header.value] : '-' }}
            </template>
          </td>
        </tr>
      </template>
   </v-data-table>
 </v-container>
</template>

<script>
import { createPaginationObject } from '@/helpers/PaginationHelper.js'
import { chipColorClass } from '@/helpers/StylingHelper.js'

export default {
  props: {
    segmentId: {
      type: Number,
      default: null
    },
    loadSegmentDepths: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    segmentDepths: []
  }),
  computed: {
    headers () {
      const headers = []
      headers.push({ text: 'Depth', value: 'depth', sortable: false })
      headers.push({ text: 'Depth UOM', value: 'depthUnitOfMeasurement', sortable: false })
      headers.push({ text: 'Name', value: 'name', sortable: false })
      headers.push({ text: 'Export Volume', value: 'exportVolume', sortable: false })
      headers.push({ text: 'Import Volume', value: 'importVolume', sortable: false })
      headers.push({ text: 'Volume UOM', value: 'volumeUnitOfMeasurement', sortable: false })
      headers.push({ text: 'Samples Required', value: 'samplesRequired', width: '50px', sortable: false })
      headers.push({ text: 'Samples Collected', value: 'samplesCollected', width: '50px', sortable: false })
      headers.push({ text: 'Samples Passed', value: 'samplesPassed', width: '50px', sortable: false })
      headers.push({ text: 'Samples Contaminated', value: 'samplesPassed', width: '50px', sortable: false })
      if (this.segmentDepths.some((depth) => depth.type.id === 1)) headers.push({ text: 'Hauls Sent', value: 'haulsSent', sortable: false })
      if (this.segmentDepths.some((depth) => depth.type.id === 2)) headers.push({ text: 'Hauls Received', value: 'haulsReceived', sortable: false })
      headers.push({ text: 'Status', value: 'status', sortable: false, align: 'center' })
      headers.push({ text: 'Actions', value: 'actions', align: 'right', sortable: false })
      return headers
    },
    filters () {
      const filters = []
      if (this.segmentId) filters.push({ name: 'SegmentId', values: [this.segmentId] })
      return filters
    }
  },
  watch: {
    // eslint-disable-next-line quote-props
    segmentId: {
      handler (value) {
        if (value) this.loadDepths()
      },
      deep: true,
      immediate: true
    },
    loadSegmentDepths: {
      handler (value) {
        if (value) {
          this.loadDepths()
        }
      }
    }
  },
  methods: {
    async loadDepths () {
      const search = createPaginationObject(null, null, this.filters)
      const res = await this.$api.segmentDepths.read(search)
      this.segmentDepths = res.segmentDepths
    },
    editDepth (item) {
      console.log(item)
    },
    deleteDepth (item) {
      console.log(item)
    },
    chipColor (name) {
      return chipColorClass(name)
    }
  }
}
</script>
