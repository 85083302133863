<template>
  <div>
    <v-row justify="center">
      <v-col cols="12">
        <v-dialog
          v-model="formOpen"
          persistent
          style="width: 80%"
          width="60%"
          min-width="600px"
        >
          <v-card style="width: 100%">
            <v-card-title>New Depth Range</v-card-title>
            <v-card-text>
              <v-container style="width: 100%">
                <v-form v-model="valid" ref="form">
                  <v-row justify="center">
                    <v-col cols="4">
                      <v-text-field
                        suffix="m"
                        v-model="submitData.startDepth"
                        :rules="[rules.required, rules.number]"
                      >
                        <template #label>
                          Start Depth<red-asterisk />
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        suffix="m"
                        v-model="submitData.endDepth"
                        :disabled="!submitData.startDepth"
                        :rules="[rules.required, rules.number, rules.minimum]"
                      >
                        <template #label> End Depth<red-asterisk /> </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-radio-group v-model="submitData.isAboveGround" row>
                        <v-radio
                          label="Above Ground"
                          color="primary"
                          :value="true"
                        ></v-radio>
                        <v-radio
                          label="Below Ground"
                          color="primary"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row class="text-right">
                    <v-spacer></v-spacer>
                    <v-btn text class="mr-2" @click="closeForm">Close</v-btn>
                    <v-btn variant="outlined" @click="submitDepthRange" elevation="0"
                      >Submit</v-btn
                    >
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    segmentDepthRangeFormIsOpen: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    formOpen: false,
    valid: false,
    defaultSubmitData: {
      isAboveGround: false,
      startDepth: null,
      endDepth: null
    },
    submitData: {}
  }),
  computed: {
    rules () {
      const rules = {
        required: (value) => !!value || 'Required.',
        number: (value) => {
          const pattern = /^\d+$/
          return pattern.test(value) || 'Must be a number'
        },
        minimum: (value) => Number(value) > this.submitData.startDepth || `End depth must be greater than ${this.submitData.startDepth}`
      }
      return rules
    }
  },
  watch: {
    segmentDepthRangeFormIsOpen: {
      handler (value) {
        this.formOpen = value
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.resetForm()
    },
    async submitDepthRange () {
      if (!this.$refs.form.validate()) return
      const res = await this.$api.depths.post(this.submitData)
      if (res.id) {
        this.$store.dispatch('app/showSuccess', 'Depth Range successfully created')
        this.$emit('submit-segment-depth-range-form', res)
      } else {
        this.$store.dispatch('app/showError', 'Failed to create Depth Range')
      }
      this.closeForm()
    },
    closeForm () {
      this.$refs.form.resetValidation()
      this.resetForm()
      this.$emit('close-segment-depth-range-form')
    },
    resetForm () {
      this.submitData = { ...this.defaultSubmitData }
    }
  }
}
</script>

<style>
.v-dialog > .v-overlay__content {
  width: 80%;
}
</style>
