<template>
  <div>
    <segment-form :site="selectedSite" :form-is-open="formIsOpen" @close-form="closeForm" @submit-form="createSegment"/>
    <v-row class="mx-2 mt-1">
      <!--<v-col cols="9">
        <v-tabs v-model="activeTab">
          <v-tab value='table'>Table</v-tab>
          <v-tab value='cards'>Cards</v-tab>
          <v-tab value='map'>Map</v-tab>
        </v-tabs>
      </v-col>-->
      <v-col cols="9">
        </v-col>
      <v-col cols="3" class="text-right justify-end d-flex align-center">
        <v-btn elevation="0" outlined prepend variant="outlined" @click="openSegmentForm()"><v-icon>mdi-plus</v-icon>New Segment</v-btn>
      </v-col>
    </v-row>

    <v-window v-model="activeTab">
      <v-window-item>
        <segments-table :segments-data="segmentsData" @showMap="(item) => showMap(item)" />
      </v-window-item>

      <!--<v-window-item>
        <sites-card :sites-data="sitesData" />
      </v-window-item>

      <v-window-item>
        <sites-map :sites-data="sitesData" :selected-site="selectedSite" />
      </v-window-item>-->
    </v-window>
  </div>

</template>

<script>
// import { ref } from 'vue'
import SegmentsTable from '@/components/segments/SegmentsTable.vue'
import SegmentForm from '@/components/segments/SegmentForm.vue'
import { createPaginationObject } from '@/helpers/PaginationHelper.js'
import { mapState } from 'vuex'// import { mapGetters, mapState, mapMutations } from 'vuex'

export default {
  components: {
    SegmentsTable,
    SegmentForm
  },
  data: () => ({
    activeTab: 0,
    siteId: null,
    segmentsData: [],
    formIsOpen: false
  }),
  computed: {
    ...mapState({
      darkMode: (state) => state.app.isDarkMode,
      sites: (state) => state.site.sites,
      selectedSiteId: (state) => state.site.selectedSiteId
    }),
    filters () {
      const filters = []
      // not sure if we need this to push the filter
      // if (this.selectedCompanyProfile.companyTypeId === this.generalContractorCompanyTypeId)
      filters.push({ name: 'SiteId', values: [this.selectedSiteId] })
      return filters
    },
    selectedSite () {
      if (this.sites && this.sites.length && this.selectedSiteId) return this.sites.find(x => x.id === this.selectedSiteId)
      return {}
    }
  },
  watch: {
    // eslint-disable-next-line quote-props
    selectedSiteId: {
      handler (value) {
        if (value) {
          this.loadSegments()
        }
      },
      deep: true,
      immediate: true
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      // await this.processQuery()
      await this.$store.dispatch('action/refreshActionCount')
      this.loadSegments()
    },
    // async processQuery () {
    //   if (this.$route.query.siteId) {
    //     this.siteId = this.$route.query.siteId
    //     this.filters = [{ name: 'SiteId', values: [this.siteId] }]
    //     // const query = { ...this.$route.query }
    //     // delete query.siteId
    //     // this.$router.replace({ query })
    //   } else this.siteId = null
    // },
    async loadSegments () {
      const search = createPaginationObject(null, null, this.filters)
      const res = await this.$api.segments.read(search)
      this.segmentsData = res.segments.map((segment) => {
        return {
          ...segment,
          expanded: false,
          segmentDepths: []
        }
      }
      )
    },
    openSegmentForm () {
      this.formIsOpen = true
    },
    closeForm () {
      this.formIsOpen = false
    },
    createSegment (row) {
      // row.id = this.segmentsData.length
      // this.segmentsData.push(row)
      this.loadSegments()
    },
    showMap (item) {
      this.activeTab = 2
      this.selectedSite = item
    }
  }

}

</script>
<style>
.v-tabs.theme--dark div.v-tabs-bar {
  background-color: #121212 !important;
}
</style>
